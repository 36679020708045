import React from "react";
import { Route, Routes } from "react-router-dom";
import { homepageURL, privacypolicyURL, termsandconditionURL } from "./components/helpers/constant-words";
import Commingsoon from "./pages/Homepage/Commingsoon";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Termsandcondition from "./pages/Terms&Condition/Terms&Condition";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path={homepageURL} element={<Commingsoon />} />
        <Route path={privacypolicyURL} element={<PrivacyPolicy />} />
        <Route path={termsandconditionURL} element={<Termsandcondition />} />
      </Routes>
    </>
  );
};
export default Routing;
