import React from "react";
import { privacyban, privacybanmob } from "../../images";
import "./terms&condition.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { homepageURL, privacypolicyURL } from "../../components/helpers/constant-words";
import { Link } from "react-router-dom";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const Termsandcondition = () => {
  return (
    <>
      <main className="main_conatiner">
        <Header />
        {/* <section className="terms_sec1">
        </section> */}
        <MetaDecorator
          metaTitle="Terms And Conditions"
          metaDesc="Terms And Conditions"
          canonicalLink={window.location}
        />

        <section className="terms_sec2">
          <div className="my_container">
            <div className="text_container">
              {/* <p class="desc">
                <strong>Last updated on</strong>: 19th July 2023
              </p> */}
              <h2 className="main_heading">Terms And Conditions</h2>
              <div className="desc">
                <p className="para">
                  {" "}
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable and
                  the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This document is published in accordance with the
                  provisions of Rule 3 (1) of the Information Technology
                  (Intermediaries guidelines) Rules, 2011 that require
                  publishing the rules and regulations, Privacy Policy and Terms
                  of Use for access or usage of “
                  <Link to={homepageURL}>
                    <strong>ARCHINZA.com</strong>
                  </Link>
                  ” website and “<strong>ARCHINZA</strong>” application for
                  mobile and handheld devices.
                </p>
              </div>
              <h4 className="heading">APPLICATION AND ACCEPTANCE OF TERMS</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    These terms of use (the “<strong>Terms of Use</strong>”)
                    govern your use of our website{" "}
                    <strong>
                      “
                      <Link to={homepageURL}>
                        archinza.com
                      </Link>
                      ”
                    </strong>{" "}
                    (the “<strong>Website</strong>”) and our {""}
                    <strong>“ARCHINZA”</strong> application for mobile and
                    handheld devices (the “<strong>App</strong>”). The Website
                    and the App are jointly referred to as the “
                    <strong>Platform</strong>” hereinafter. Please read these
                    Terms of Use carefully before you use the services. If you
                    do not agree to these Terms of Use, you may not use the
                    services on the Platform, and we request you to uninstall
                    the App. By installing, downloading or even merely using the
                    Platform, you shall be contracting with ARCHINZA and you
                    signify your acceptance to the Terms of Use and other
                    ARCHINZA policies (including but not limited to the
                    Cancellation & Refund Policy, Privacy Policy and Take Down
                    Policy) as posted on the Platform from time to time, which
                    takes effect on the date on which you download, install or
                    use the Services, and create a legally binding arrangement
                    to abide by the same.
                  </li>
                  <li>
                    The Platform is operated and owned by, Archinza Connect Pvt.
                    Ltd., having its registered office at E2/6, Vasant Vihar,
                    New Delhi 110057.{" "}
                  </li>
                  <li>
                    For the purpose of these Terms of Use, wherever the context
                    so requires, “<strong>you</strong>” or “
                    <strong>Archinza Pro</strong>” shall mean any natural or
                    legal person who has agreed to become a listed seller, user
                    on the Platform by providing Registration Data while
                    registering on the Platform as a registered seller / user
                    using any computer system.
                  </li>
                  <li>
                    The brands/ Firms/ Freelancers/ Individuals/ Businesses that
                    will list product(s)/ service(s)/event(s) for being offered
                    on the Platform are hereinafter referred to as{" "}
                    <strong>“Archinza Business”</strong>. The
                    product(s)/service(s)/event(s) are collectively referred to
                    as “<strong>products</strong>” hereinafter.
                  </li>
                  <li>
                    The terms “We”, “Us”, “Our” shall mean archinza.com or {""}
                    <strong>ARCHINZA</strong> or Archinza Connect Pvt. Ltd. The
                    term “ARCHINZA” have been used hereinafter to refer to us.{" "}
                  </li>
                  <li>
                    This Website or mobile application/ Whatsapp Bot and any
                    individual web Platform or merchant-specific, city-specific,
                    or state-specific Platform/ Whatsapp Bot now or hereinafter
                    contained within or otherwise available through external
                    hyperlinks (“<strong>MiniPlatform</strong>”) with our
                    Website or mobile application are private property. All
                    interactions on this Website or mobile application or Bots
                    must comply with these Terms of Use. If you do not wish to
                    agree to the outlined terms and conditions (the “
                    <strong>Terms of Use</strong>” in this document) please
                    refrain from using this Website or mobile application or
                    Bot, even if on an external platform. The term Website shall
                    include any or all MiniPlatform, external Bot API, External
                    Bot on any other App, wherever mentioned.
                  </li>
                </ol>
              </div>
              <h4 className="heading">AMENDMENT </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    We reserve the right to amend these terms and conditions at
                    any time. All amendments to these terms and conditions will
                    be posted on-line. Such changes may include, among other
                    things, the adding of certain fees or charges. We suggest to
                    you, therefore, that you re-read this important notice
                    containing our Terms of Use and Privacy Policy from time to
                    time so that you stay informed as to any such changes. If we
                    make changes to our Terms of Use and Privacy Policy and you
                    continue to use our Website or mobile application, you are
                    implicitly agreeing to the amended Terms of Use and Privacy
                    Policy. Unless specified otherwise, any such deletions or
                    modifications shall be effective immediately upon ARCHINZA’s
                    posting thereof. Continued use of any of the services
                    provided by ARCHINZA via the platform (or via other
                    electronic or other communication from ARCHINZA) including
                    the information services, content and transaction
                    capabilities on the platform, including the ability to make
                    a purchase (all of which are called the “Services” in this
                    document) will be deemed to constitute acceptance of the new
                    terms and conditions. ARCHINZA reserves the right to
                    immediately terminate these Terms or any Services with
                    respect to you, or generally cease offering or deny access
                    to the Services or any portion thereof, at any time for any
                    reason.
                  </li>
                </ol>
              </div>
              <h4 className="heading">
                WEBSITE OR APP or BOT on any external application – MERELY A
                VENUE
              </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    The platform acts as a venue for User(s) to negotiate and
                    interact & connect with Archinza Business(s) for entering
                    into negotiations in respect thereof for sale or supply of
                    goods or services. ARCHINZA is not a party to any
                    negotiations that take place between the User(s) and the
                    Archinza Business(es) and is further not a party to any
                    agreement including an agreement for sale or supply of goods
                    or services or otherwise, concluded between the User(s) and
                    the Archinza Business(es) of the platform.{" "}
                  </li>
                  <li>
                    ARCHINZA does not control and is not liable in respect of or
                    responsible for the quality, safety, genuineness, lawfulness
                    or availability of the products or services offered for sale
                    on the platform or the ability of the Archinza Business(s)
                    selling or supplying the goods or services to complete a
                    sale or the ability of User(s) purchasing goods or services
                    to complete a purchase. This agreement shall not be deemed
                    to create any partnership, joint venture, or any other joint
                    business relationship between ARCHINZA and any other party.{" "}
                  </li>
                </ol>
              </div>
              <h4 className="heading">USER ACCOUNT, PASSWORD AND SECURITY</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    With respect to the use of Platform, each and every person
                    who registers on the Platform is a User, therefore for this
                    clause Archinza Pro are also included when the term User(s)
                    is used hereinafter.{" "}
                  </li>
                  <li>
                    In order to use the services of ARCHINZA and list the
                    products on the Platform, User(s) must register for and
                    maintain an active personal user Services account (“
                    <strong>Account</strong>”). Everyone registered on the
                    Platform must be at least 18 years of age, or the age of
                    legal majority in your jurisdiction (if different than 18),
                    to obtain an Account. Account registration requires you to
                    submit to the platform certain personal information, such as
                    your name, address, mobile phone number, and at least one
                    valid payment method (either a credit card or accepted
                    payment partner) at the time of making payment. User(s)
                    agree to maintain accurate, complete, and up-to-date
                    information in User’s Account. User’s failure to maintain
                    accurate, complete, and up-to-date Account information,
                    including having an invalid or expired payment method on
                    file, may result in User’s inability to access and use the
                    Services or the platform’s termination of these Terms with
                    the User(s). Users are responsible for all activity that
                    occurs under User’s Account, and agree to maintain the
                    security and secrecy of Account username and password at all
                    times. Unless otherwise permitted by the platform in
                    writing, User(s) may only possess one Account.
                  </li>
                  <li>
                    The right to use this platform is personal to the User(s)
                    and is not transferable to any other person or entity. The
                    User(s) shall be responsible for protecting the
                    confidentiality of User’s password(s), if any. User(s)
                    understands and acknowledges that, although the Internet is
                    often a secure environment, sometimes there are
                    interruptions in service or events that are beyond the
                    control of ARCHINZA, and ARCHINZA shall not be responsible
                    for any data lost while transmitting information on the
                    Internet.
                  </li>
                  <li>
                    User(s) agree to: immediately notify ARCHINZA of any
                    unauthorized news/breach of your password or account and
                    ensure that you exit from your account at the end of each
                    session
                  </li>
                </ol>
              </div>
              <h4 className="heading">SERVICES OFFERED</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA provides a number of Internet-based services
                    through the Platform & Bot (which may be on other
                    applications). These services include listing of products of
                    various Archinza Pro(s) and facilitating the User(s) to
                    connect to the Archinza Pro(s) dealing in the products of
                    their choice. However, the sale/purchase of products is
                    governed by the policies of Archinza Pro(s) and ARCHINZA is
                    not a party to any such transaction.
                  </li>
                  <li>
                    In addition, these Terms of Use may be further supplemented
                    by Product specific conditions, which may be displayed with
                    that Product. ARCHINZA does not warrant that Product
                    description or other content on the Platform is accurate,
                    complete, reliable, current, or error-free and assumes no
                    liability in this regard
                  </li>
                  <li>
                    ARCHINZA provides an interactive online service owned and
                    operated by ARCHINZA through the Website on the World Wide
                    Web of the Internet (the “<strong>Web</strong>” or “
                    <strong>Internet</strong>”), its mobile application, its Bot
                    (nested on other applications like Whatsapp via official
                    API) consisting of information services, content and
                    transaction capabilities provided by ARCHINZA, its holding
                    company and its associates, if any, with whom it has
                    business relationships (“
                    <strong>Business Associates</strong>”) including but not
                    limited to third parties that provide services in relation
                    to creation, production or distribution of content for the
                    Website or mobile application (“
                    <strong>Third Party Content Providers</strong>”), third
                    parties that provide advertising/ marketing services to
                    ARCHINZA (“<strong>Third Party Advertisers</strong>”) and
                    third parties that perform function on behalf of{" "}
                    <strong>ARCHINZA</strong> like sending out and distributing
                    our administrative and promotional emails and text messages
                    (“<strong>Third Party Service Providers</strong>”).
                  </li>
                </ol>
              </div>
              <h4 className="heading">AVAILABILITY OF THE PLATFORM:</h4>
              <div className="desc">
                <p className="para">
                  ARCHINZA reserves the right, in its sole discretion, to
                  terminate the access to any or all ARCHINZA Platform(s) and
                  the related services or any portion thereof at any time,
                  without notice. ARCHINZA shall also have the right at any time
                  to change or discontinue any aspect or feature of the Platform
                  or Bot, including, but not limited to, content, graphics,
                  Offers, settings, hours of availability and equipment needed
                  for access or use. Further, ARCHINZA may discontinue
                  disseminating any portion of information or category of
                  information, may change or eliminate any transmission method
                  and may change transmission speeds or other signal
                  characteristics.
                </p>
              </div>
              <h4 className="heading">USER CONDUCT</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    With respect to the use of Platform, each and every person
                    who registers on the Platform is a User, therefore for this
                    clause Archinza Pro are also included when the term User(s)
                    is used hereinafter.{" "}
                  </li>
                  <li>
                    As a condition of User’s access to and use of the Platform
                    or Services, User(s) agree that User(s) will comply with all
                    applicable laws and regulations when accessing or using the
                    Platform or Services.
                  </li>
                  <li>
                    Restrictions: The User(s) undertakes without limitation, not
                    to use or permit anyone else to use the Service or Platform
                    :
                    <ul>
                      <li>
                        to upload, send or receive any information for which you
                        have not obtained all necessary license and/or approvals
                        (from us or third parties); or which constitutes or
                        encourages conduct that would be considered a criminal
                        offence, give rise to civil liability or otherwise be
                        contrary to the law of or infringe the rights of any
                        third party in any country in the world;
                      </li>
                      <li>
                        to upload, send or receive any material which is
                        technically harmful (including computer viruses, logic
                        bombs, Trojan horses, worms, harmful components,
                        corrupted data, malicious software, harmful data, or
                        anything else which may interrupt, interfere with,
                        corrupt or otherwise cause loss, damage, destruction or
                        limitation to the functionality of any software or
                        computer equipment);
                      </li>
                      <li>
                        to intercept or attempt to intercept any communications
                        transmitted by way of a telecommunication system;
                      </li>
                      <li>
                        for a purpose other than which we have designed them or
                        intended them to be used;
                      </li>
                      <li>for any fraudulent purposes;</li>
                      <li>
                        in any way which is calculated to incite hatred against
                        any ethnic, religious or any other minority or is
                        otherwise calculated to adversely affect any individual,
                        group or entity;
                      </li>
                      <li>
                        to upload, send or receive any material, which is not
                        civil or tasteful
                      </li>
                      <li>
                        to upload, send or receive any material, including User
                        Content, which is unlawful, harmful, tortious,
                        threatening, abusive, harassing, hateful, racist, any
                        specific gender of LGBTQIA++ phobic, infringing,
                        pornographic, violent, misleading, grossly offensive, of
                        an indecent, obscene or menacing character, blasphemous
                        or defamatory or of a libelous nature of any person or
                        entity, in contempt of court or in breach of confidence,
                        or which infringes the rights of another person or
                        entity, including copyrights, trademarks, trade secrets,
                        patents, rights of personality, publicity or privacy or
                        any other third party rights;
                      </li>
                      <li>
                        to cause annoyance, inconvenience or needless anxiety.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Forbidden uses: The following uses of the Platform and
                    Services are expressly prohibited and the User(s) undertake
                    not to do (or to permit anyone else to do) any of the
                    following:
                    <ul>
                      <li>resell the Services or Platform;</li>
                      <li>
                        furnish false data including false names, addresses and
                        contact details and fraudulent use of credit/debit card
                        numbers;
                      </li>
                      <li>
                        attempt to circumvent our security or network including
                        accessing data not intended for you, logging into a
                        server or Account you are not expressly authorized to
                        access, or probe the security of other networks (such as
                        running a port scan);
                      </li>
                      <li>
                        access the Services (or Platform) in such a way as to,
                        or commit any act that would or does, impose an
                        unreasonable or disproportionately large burden on our
                        infrastructure or that otherwise interrupts or
                        interferes with its functionality, efficiency or
                        operation;
                      </li>
                      <li>
                        execute any form of network monitoring which will
                        intercept data not intended for you;
                      </li>
                      <li>
                        send unsolicited mail messages, including the sending of
                        "junk mail" or other advertising material to individuals
                        who did not specifically request such material. You are
                        explicitly prohibited from sending unsolicited bulk mail
                        messages. This includes bulk mailing of commercial
                        advertising, promotional, or informational
                        announcements, and political or religious tracts. Such
                        material may only be sent to those who have explicitly
                        requested it. If a recipient asks to stop receiving
                        email of this nature, you may not send that person any
                        further email;
                      </li>
                      <li>
                        create or forward "chain letters" or other "pyramid
                        schemes" of any type, whether or not the recipient
                        wishes to receive such mailings;
                      </li>
                      <li>
                        send malicious email, including flooding a user or site
                        with very large or numerous emails;
                      </li>
                      <li>
                        enter into fraudulent interactions or transactions with
                        us, a Seller or a Archinza Business (which shall include
                        entering into interactions or transactions purportedly
                        on behalf of a third party where you have no authority
                        to bind that third party or you are pretending to be a
                        third party);
                      </li>
                      <li>
                        use the Services or Platform (or any relevant
                        functionality of either of them) in breach of this
                        Agreement;
                      </li>
                      <li>
                        use in an unauthorized manner, or forge, mail header
                        information;
                      </li>
                      <li>
                        engage in any unlawful or criminal activity in
                        connection with the use of the Services and/or the
                        Platform or any Voucher; or
                      </li>
                      <li>
                        copy or use any User Content for any commercial
                        purposes.
                      </li>
                    </ul>
                  </li>
                  <li>
                    ARCHINZA reserves the right to prevent the User(s) from
                    using the Platform and the Service (or any part of thereof)
                    and to prevent you from making any purchase, if User’s
                    conduct is found to be in question or contravention of such
                    Terms as mentioned above or in this Agreement.
                  </li>
                </ol>
              </div>
              <h4 className="heading">
                TRANSACTION BETWEEN USERS AND ARCHINZA PRO
              </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    Through the Platforms, ARCHINZA provides electronic
                    web-based platforms for exchanging information between
                    buyers and sellers of products and services. ARCHINZA
                    additionally provides electronic web-based transaction
                    platforms for Merchants to place, accept, conclude, manage
                    and fulfill orders for the provision of products and
                    services online within the Platforms. However, for any
                    Services, ARCHINZA does not represent either the seller or
                    the buyer in specific transactions. ARCHINZA does not
                    control and is not liable or responsible for the quality,
                    safety, lawfulness or availability of the products or
                    services offered for sale on the Platforms, the ability of
                    the sellers to complete a sale or delivery or the ability of
                    buyers to complete a purchase.
                  </li>
                  <li>
                    Users are hereby made aware that there may be risks of
                    dealing with people acting under false pretenses. ARCHINZA
                    uses several techniques to verify the accuracy of certain
                    information, our paying User(s) provide us when they
                    register for a paying Merchant ship service on the
                    Platforms. However, because user verification on the
                    Internet is difficult, ARCHINZA cannot and does not confirm
                    each User's purported identity (including, without
                    limitation, paying Merchants). We encourage User(s) to use
                    various means, as well as common sense, to evaluate with
                    whom you are dealing.
                  </li>
                  <li>
                    User(s) and Archinza Pro(s) accessing or using the Platforms
                    or Services shall assume the risks of conducting any
                    purchase and sale transactions in connection with or through
                    the Platforms. User(s) and Archinza Pro(s) accessing or
                    using the Platforms shall also fully assume all risks of
                    liability or harm of any kind arising out of or in
                    connection with any subsequent activity relating to the
                    products or services that are the subject of the
                    transactions on the Platforms. Examples of such risks shall
                    include, but are not limited to, mis-representation of
                    products and services, fraudulent schemes, unsatisfactory
                    product quality, failure to meet specifications, defective
                    or dangerous products, unlawful products, delay or default
                    in delivery or payment, cost mis-calculations, breach of
                    warranty, breach of contract, transportation accidents, the
                    risk that the manufacture, importation, export,
                    distribution, offer, display, purchase, sale and/or use of
                    products or services offered or displayed on the Platforms
                    may violate or may be asserted to violate Third Party
                    Rights, and the risk that User(s) and Archinza Pro(s) may
                    incur costs of defense or other costs in connection with
                    third parties’ assertion of Third Party Rights, or in
                    connection with any claims by any party that they are
                    entitled to defense or indemnification in relation to the
                    assertion of rights, demands or claims by claimants of Third
                    Party Rights. Examples of such risks also include the risk
                    of claims from consumers, other purchasers, end-users of
                    products or other third parties that they have suffered
                    injuries or harm from their use of the products obtained
                    through the Platforms or Services. All of the foregoing
                    risks are referred to as “Transaction Risks”. ARCHINZA is
                    not liable or responsible for any damages, claims,
                    liabilities, costs, harm, inconveniences, business
                    disruptions or expenditures of any kind that may arise a
                    result of or in connection with any Transaction Risks.
                  </li>
                  <li>
                    User(s) and Archinza Pro(s) on the Platforms are solely
                    responsible for setting out and performance of the terms and
                    conditions of the transactions conducted on, through or as a
                    result of use of the Platforms, including, without
                    limitation, terms regarding payment, returns, warranties,
                    shipping, insurance, fees, taxes, title, licenses, fines,
                    permits, handling, transportation and storage, subject to
                    any additional obligations.
                  </li>
                  <li>
                    {" "}
                    User(s) and Divapreneur(s) agrees to provide all information
                    and materials as may be reasonably required by ARCHINZA in
                    connection with your transactions conducted on, through or
                    as a result of use of the Platforms or Services. ARCHINZA
                    has the right to suspend or terminate any User’s and
                    Archinza Pro(s) account if the User(s) and Archinza Pro(s)
                    fails to provide the required information and materials
                    without liability for any losses or damages arising out of
                    or in connection with such suspension or termination.
                  </li>
                  <li>
                    In the event that any User(s) and Archinza Pro(s) has a
                    dispute with any party to a transaction, such User agrees to
                    release and indemnify ARCHINZA (and our agents, affiliates,
                    directors, partners, collaborator, officers and employees)
                    from all claims, demands, actions, proceedings, costs,
                    expenses and damages (including without limitation any
                    actual, special, incidental or consequential damages)
                    arising out of or in connection with such dispute or the
                    transaction.
                  </li>
                </ol>
              </div>
              <h4 className="heading">User’s Responsibility:</h4>
              <div className="desc">
                <p className="para">
                  The Services and use of the website or mobile application do
                  not include the provision of a computer or other necessary
                  equipment to access the Site or the Services, nor the service
                  itself. To use the Site or Services, you will require Internet
                  connectivity and appropriate telecommunication links, and
                  connect directly with the leads. The User shall be responsible
                  for obtaining and maintaining all telephone, computer
                  hardware, software and other equipment needed for access to
                  and use of this Website or mobile application and all charges
                  related thereto. Archinza.com shall not be liable for any
                  damages to the User’s equipment resulting from the use of this
                  Website or mobile application.
                </p>
              </div>
              <h4 className="heading"> INTELLECTUAL PROPERTY RIGHTS</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA grants you a limited, personal, non-transferable,
                    non-exclusive, revocable license to access and use the Site
                    and its content pursuant to this Agreement and to any
                    additional terms and policies set forth by ARCHINZA. All
                    Intellectual Property Rights (including all copyright,
                    patents, trademarks, service marks, trade names, designs
                    (including the "look and feel" and other visual or
                    non-literal elements) whether registered or unregistered in
                    the Platform and Service, (subject to the Title & Ownership
                    Rights as mentioned below) information content on the
                    Platform or accessed as part of the Service, any database
                    operated by us and all the Website design, text and
                    graphics, software, photos, video, music, sound, and their
                    selection and arrangement, and all software compilations,
                    underlying source code and software (including applets and
                    scripts) shall remain our property (or that of our
                    licensors). You shall not, and shall not attempt to, obtain
                    any title to any such intellectual property. All rights in
                    this sense are reserved by archinza.com. <br />
                    Logos and variations thereof found on the website or mobile
                    application are trademarks owned by{" "}
                    <strong>
                      Archinza Connect Pvt. Ltd, or its directors/ licensors,
                    </strong>{" "}
                    and all use of these marks inures to the benefit of
                    archinza.com. All rights (including goodwill and, where
                    relevant, trade marks) in archinza.com’s name are owned by
                    us (or our licensors). Other product and company names
                    mentioned on the Website or mobile application is the trade
                    marks or registered trade marks of their respective owners.
                  </li>
                  <li>
                    ARCHINZA respects the intellectual property rights of others
                    and we expect our Archinza Pro(s) to do the same. None of
                    the material listed in the clause above may be reproduced or
                    redistributed or copied, distributed, republished,
                    downloaded, displayed, posted or transmitted in any form or
                    by any means, sold, rented or sub-licensed, used to create
                    derivative works, or in any way exploited without our prior
                    express written permission. You may, however, retrieve and
                    display the content of the Website on a computer screen,
                    store such content in electronic form on disk (but not on
                    any server or other storage device connected to a network)
                    or print / save one copy of such content for your own
                    personal, non-commercial use, provided you keep intact all
                    and any copyright and proprietary notices. You may not
                    otherwise reproduce, modify, copy or distribute or use for
                    commercial purposes any of the materials or content on the
                    Website or mobile application without our permission.
                  </li>
                  <li>
                    All rights, not otherwise claimed under this Agreement by
                    ARCHINZA, are hereby reserved. Any information or
                    advertisements contained on, distributed through, or linked,
                    downloaded or accessed from any of the services contained on
                    the Web Site or any offer displayed on or in connection with
                    any service offered on the platform (“
                    <strong>Platform Information</strong>”) is intended, solely
                    to provide general information for the personal use of the
                    User(s) and Archinza Pro(s)who fully accept any and all
                    responsibility and liabilities arising from and out of the
                    use of such Information. ARCHINZA does not represent,
                    warrant or endorse in any manner the accuracy or reliability
                    of Platform Information, or the quality of any products
                    and/or services obtained by the User(s) as a result of any
                    Platform Information
                  </li>
                  <li>
                    The Information is provided “as is” with no guarantee of
                    completeness, accuracy, timeliness or of the results
                    obtained from the use of the Information, and without
                    warranty of any kind, express or implied, including, but not
                    limited to warranties of performance, merchantability and
                    fitness for a particular purpose. Nothing contained in the
                    Agreement shall to any extent substitute for the independent
                    investigations and the sound technical and business judgment
                    of the User(s) and Divapreneur(s). In no event shall
                    ARCHINZA be liable for any direct, indirect, incidental,
                    punitive, or consequential damages of any kind whatsoever
                    with respect to ARCHINZA’s Services. Archinza Pro(s) hereby
                    acknowledge that any reliance upon the Information shall be
                    at their sole risk and further understand and acknowledge
                    that the Information has been compiled from publicly aired
                    and published sources. ARCHINZA respects the rights of such
                    entities and cannot be deemed to be infringing on the
                    respective copyrights or businesses of such entities.
                    ARCHINZA reserves the right, in its sole discretion and
                    without any obligation, to make improvements to, or correct
                    any error or omissions in any portion of the Information.
                    <ul>
                      <li>
                        Trademark - “ARCHINZA”, the ARCHINZA Logos and
                        variations thereof found on the website or mobile
                        application are trademarks owned by{" "}
                        <strong>ARCHINZA</strong> or our licensor(s), and all
                        use of these marks inures to the benefit of{" "}
                        <strong>ARCHINZA.</strong> All rights (including
                        goodwill and, where relevant, trade marks) in ARCHINZA’s
                        name are owned by us (or our licensors). Other product
                        and company names mentioned on the Platform are the
                        trademarks or registered trademarks of their respective
                        owners.
                      </li>
                      <li>
                        Copyright – All the Platform content including Platform
                        Information is copyrighted to ARCHINZA excluding any
                        third-party content and any links to any third-party web
                        Platform being made available or contained on the
                        Platform. User(s) may not use any trademark, service
                        mark or logo of any independent third parties without
                        prior written approval from such parties.
                      </li>
                      <li>
                        URL’s/Sub- Domain - URL’s/ Sub-domain names assigned by
                        ARCHINZA to User(s){" "}
                        {"{including both paid and free User(s)}"} are the
                        exclusive property of ARCHINZA and it cannot be assumed
                        to be permanent in any case. ARCHINZA reserve the right,
                        without prior notice, at any point of time, to suspend
                        or terminate or restrict access to or edit any
                        URL's/Sub-domain names. IN ALL SUCH CASES, ARCHINZA WILL
                        NOT BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
                        SPECIAL OR OTHER CONSEQUENTIAL DAMAGES, INCLUDING,
                        WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS
                        INTERRUPTION OR OTHERWISE.ARCHINZA may allow User(s) and
                        Archinza Pro(s) access to content, products or services
                        offered by third parties through hyperlinks (in the form
                        of word link, banners, channels or otherwise) to the
                        webPlatform offered by such third parties (
                        <strong>"Third Party Web Platform"</strong>). ARCHINZA
                        advises its User(s) and Archinza Pro(s) to read the
                        terms and conditions of use and/or privacy policies
                        applicable in respect of such Third Party Web Platform
                        prior to using or accessing such Third Party Web
                        Platform. Archinza Pro(s) acknowledge and agree that
                        ARCHINZA has no control over any content offered on
                        Third Party Web Platform, does not monitor such Third
                        Party Web Platform, and shall in no manner be deemed to
                        be liable or responsible to any person for such Third
                        Party Platform, or any content, products or services
                        made available thereof.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Subject to the Clause below, any material you transmit or
                    post or submit to the Platform (or otherwise to us) shall be
                    considered (and we may treat it as) non-confidential and
                    non-proprietary, subject to our obligations under data
                    protection legislation or such legislation which provides
                    for protection of electronic data under Indian Laws. If for
                    some reason, any part of that statement does not work as a
                    matter of law, then for anything which you supply to us from
                    whatever source (i.e. via email, the Website or otherwise)
                    you grant us a royalty-free, perpetual, irrevocable,
                    non-exclusive right to use, copy, modify, adapt, translate,
                    publish and distribute world-wide any such material.
                  </li>
                  <li>
                    All comments, suggestions, ideas, notes, drawings, concepts
                    or other information: (i) disclosed or offered to us by you;
                    or (ii) in response to solicitations by us regarding the
                    Service or the Platform; (in each foregoing case, these are
                    called <strong>"Ideas"</strong>) shall be deemed to be and
                    shall remain our property and you hereby assign by way of
                    present and future assignment all intellectual property
                    rights in Ideas, to us. You understand and acknowledge that
                    we have both internal resources and other external resources
                    which may have developed or may in the future develop ideas
                    identical to or similar to Ideas and that we are only
                    willing to consider Ideas on these terms. In any event, any
                    Ideas are not submitted in confidence and we assume no
                    obligation, express or implied by considering it. Without
                    limitation, we shall exclusively own all now known or
                    hereafter existing rights to the Ideas of every kind and
                    nature throughout the world and shall be entitled to
                    unrestricted use of the Ideas for any purpose whatsoever,
                    commercial or otherwise without compensation to the provider
                    of the Ideas.
                  </li>
                  <li>
                    In addition, use of the ARCHINZA Content for any purpose not
                    expressly permitted in this Agreement is prohibited and
                    entitles ARCHINZA to initiate appropriate legal action.
                    Archinza Pro(s) agree that as a condition of their access to
                    and use of ARCHINZA Services, they will not use ARCHINZA’s
                    Services to infringe the intellectual property rights of any
                    third parties in any way. ARCHINZA reserves the right to
                    terminate the right of any Archinza Pro(s) to access or use
                    ARCHINZA’s Services for any infringement of the rights of
                    third parties in conjunction with use of the ARCHINZA’s
                    Service, or in the event ARCHINZA is of the beliefs that
                    Archinza Pro(s) conduct is prejudicial to the interests of
                    ARCHINZA, its affiliates, or other User(s) or Archinza
                    Pro(s) or for any other reason, at ARCHINZA’s sole
                    discretion, with or without cause.
                  </li>
                  <li>
                    Furthermore, ARCHINZA disclaims any liability for the breach
                    of intellectual property rights of the images that are shown
                    or displayed by the Archinza Business(es). ARCHINZA do not
                    have any knowledge as to who is the original creator of such
                    image and therefore holds no liability for any breach of
                    anyone’s rights in this sense. The Archinza Pro(s)
                    acknowledge and warrants that any kind of plagiarism of
                    pictures is unknown to ARCHINZA and we hold no liability in
                    this regard.
                  </li>
                </ol>
              </div>
              <h4 className="heading">BREACH BY ARCHINZA PRO</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA reserves the right in our sole discretion to
                    remove, modify or reject any User Content that the Archinza
                    Pro(s) submit to, post or display on the Platform which we
                    reasonably believe is unlawful, violates the Terms, could
                    subject ARCHINZA or our affiliates to liability, or is
                    otherwise found inappropriate in ARCHINZA’s sole discretion.
                  </li>
                  <li>
                    {" "}
                    If any Archinza Pro(s) breaches any Terms, or if ARCHINZA
                    has reasonable grounds to believe that a Archinza Pro(s) is
                    in breach of any Terms, ARCHINZA shall have the right to
                    take such disciplinary actions as it deems appropriate,
                    including without limitation: (i) suspending or terminating
                    the Archinza Pro(s) account and any and all accounts
                    determined to be related to such account by ARCHINZA in its
                    sole discretion without liability for any losses or damages
                    arising out of or in connection with such suspension or
                    termination; (ii) restricting, downgrading, suspending or
                    terminating the subscription of, access to, or current or
                    future use of any Service; (iii) removing any product
                    listings or other User Content that the Archinza Pro(s) has
                    submitted, posted or displayed, or imposing restrictions on
                    the number of product listings or User Content that the
                    Archinza Pro(s) may post or display; (iv) imposing other
                    restrictions on the Archinza Pro(s) use of any features or
                    functions of any Service as ARCHINZA may consider
                    appropriate in its sole discretion; and (v) any other
                    corrective actions, discipline or penalties as ARCHINZA may
                    deem necessary or appropriate in its sole discretion.
                  </li>
                  <li>
                    Without limiting the generality of the provisions of the
                    Terms, Archinza Pro(s) would be considered as being in
                    breach of the Terms in any of the following circumstances:
                    <ul>
                      <li>
                        upon complaint or claim from any third party, ARCHINZA
                        has reasonable grounds to believe that such Archinza
                        Pro(s) has willfully or materially failed to perform
                        your contract with such third party including without
                        limitation where Archinza Pro(s) who supplies products
                        using the Platform has failed to deliver any items
                        ordered by such third party after receipt of the
                        purchase price, or where the items such Archinza Pro(s)
                        has delivered materially fail to meet the terms and
                        descriptions outlined in your contract with such third
                        party, b) ARCHINZA has reasonable grounds to suspect
                        that such Archinza Pro(s) has used a stolen credit card
                        or other false or misleading information in any
                        transaction with a counter party, c) ARCHINZA has
                        reasonable grounds to suspect that any information
                        provided by the Archinza Pro(s) is not current or
                        complete or is untrue, inaccurate, or misleading, or d)
                        ARCHINZA believes that the Archinza Pro(s) actions may
                        cause financial loss or legal liability to ARCHINZA or
                        our affiliates or any other Users.
                      </li>
                      <li>
                        ARCHINZA reserves the right to cooperate fully with
                        governmental or regulatory authorities, law enforcement
                        bodies, private investigators and/or injured third
                        parties in the investigation of any suspected criminal
                        or civil wrongdoing. Further, to the extent permitted by
                        applicable laws and policies, ARCHINZA may disclose the
                        Archinza Pro(s) identity, contact information and/or
                        information regarding the Archinza Pro(s) account(s),
                        transactions or activities carried out on or via the
                        Platform, if requested by a government, regulatory or
                        law enforcement body or an injured third party, or as a
                        result of a subpoena or other legal action. ARCHINZA
                        shall not be liable for damages or results arising from
                        such disclosure, and Archinza Pro(s) agrees not to bring
                        any action or claim against ARCHINZA for such
                        disclosure.
                      </li>
                      <li>
                        ARCHINZA may, at any time and in our reasonable
                        discretion, impose limitations on, suspend or terminate
                        the Divapreneur’s use of any Service or the Platform
                        without being liable to the Archinza Pro(s) if ARCHINZA
                        has received notice that the Archinza Pro(s) is/are in
                        breach of any agreement or undertaking with any
                        affiliate of ARCHINZA and such breach involves or is
                        reasonably suspected to involve dishonest or fraudulent
                        activities. ARCHINZA reserve the right to, but shall not
                        be required to investigate such breach or request
                        confirmation from the Archinza Pro(s).
                      </li>
                      <li>
                        Each Archinza Pro(s) agrees to indemnify ARCHINZA, our
                        affiliates, directors, employees, agents and
                        representatives and to hold them harmless, from any and
                        all damages, losses, claims and liabilities (including
                        legal costs on a full indemnity basis) which may arise
                        from your submission, posting or display of any User
                        Content, from your access to or use of the Platform or
                        Services, or from your breach of the Terms or any
                        Additional Agreements.
                      </li>
                      <li>
                        {" "}
                        Each Archinza Pro(s) further agrees that ARCHINZA is not
                        responsible, and shall have no liability to you or
                        anyone else for any User Content or other material
                        transmitted through the Platform or Services, including
                        fraudulent, untrue, misleading, inaccurate, defamatory,
                        offensive or illicit material and that the risk of
                        damage from such User Content or other material rests
                        entirely with the Archinza Business (s). ARCHINZA
                        reserves the right, at our own expense, to assume the
                        exclusive defense and control of any matter otherwise
                        subject to indemnification by the Archinza Pro(s) in
                        which event the Archinza Pro(s) shall cooperate with
                        ARCHINZA in asserting any available defenses.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              <h4 className="heading">
                DUPLICATION OR OVERLAP OF ARCHINZA BUSINESS
              </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    Archinza Businesss are mostly brands that are home grown and
                    such brands are usually not registered under specific
                    intellectual property rights/government stipulation. There
                    may be an occurrence where two Archinza Businesss are
                    registered on the Platform under the same name. In such a
                    case the ARCHINZA algorithm will be provided on FIRST COME
                    FIRST SERVE basis only. ARCHINZA is not a party to any of
                    the disputes arising between Archinza Businesss and holds no
                    liability in any such case. If any dispute arises between
                    Divapreneur(s), the same has to be resolved by the disputant
                    parties themselves and ARCHINZA has no role to play in it.
                  </li>
                </ol>
              </div>
              <h4 className="heading">PROMOTION OF EVENTS</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA Platform is only an aggregator/listing for
                    discovery, and not a service provider. If in any case, any
                    kind of event is promoted on the ARCHINZA Platform, which is
                    either done by ARCHINZA for us or is done by the Platform
                    for someone else, the only service that ARCHINZA is
                    providing here is of promotion, curation, planning and/or
                    information of the event.{" "}
                  </li>
                  <li>
                    Archinza Pro(s)and User(s) warrants and agrees that no
                    liability arises on the part of ARCHINZA in the case of any
                    monetary transaction, any kind of liability which arises
                    from the promotion of such events i.e. venue of the event,
                    look of the show, amenities provided by the event organisers
                    or any other activity that is related to the event. The only
                    task taken up by us is to promote, curate, plan and/or
                    inform about the event and we disclaim any other liability
                    that arises out of it.{" "}
                  </li>
                  <li>
                    The liability is disclaimed with regard to the above clause,
                    when any of the Archinza Pro(s) organizes an event and the
                    same is promoted by us on our Platform when Archinza Pro(s)
                    have used our database wherein the database has been
                    formally shared by us.{" "}
                  </li>
                  <li>
                    The liability on the part of ARCHINZA is also disclaimed
                    with regard to clause 12.2, wherein any of the Archinza
                    Pro(s) organizes an event and the same in promoted by us on
                    our Platform when Archinza Pro(s) have used our database
                    wherein the database haven’t been formally shared by us.{" "}
                  </li>
                  <li>
                    Furthermore, in future if any virtual event is promoted by
                    us, the details and the declaration for the same will be
                    sent to Archinza Pro(s) and User(s) through a separate mail
                    and the declaration mentioned in the mail shall apply to it.
                  </li>
                </ol>
              </div>
              <h4 className="heading">PROMOTIONAL CODES</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA may, in ARCHINZA's sole discretion, create
                    promotional codes that may be redeemed for Account credit,
                    or other features or benefits related to the Services and/or
                    a Third Party Provider’s services, subject to any additional
                    terms that ARCHINZA establishes on a per promotional code
                    basis <em>(“Promo Codes”)</em>.{" "}
                  </li>
                  <li>
                    You agree that Promo Codes:
                    <ul>
                      <li>
                        must be used for the intended audience and purpose, and
                        in a lawful manner;{" "}
                      </li>
                      <li>
                        may not be duplicated, sold or transferred in any
                        manner, or made available to the general public (whether
                        posted to a public form or otherwise), unless expressly
                        permitted by ARCHINZA;{" "}
                      </li>
                      <li>
                        may be disabled by ARCHINZA at any time for any reason
                        without any liability to ARCHINZA;{" "}
                      </li>
                      <li>
                        may only be used pursuant to the specific terms that
                        ARCHINZA establishes for such Promo Code;{" "}
                      </li>
                      <li>are not valid for cash; </li>
                      <li>may expire prior to your use; and </li>
                      <li>
                        unless otherwise specified cannot be used more than
                        once.
                      </li>
                    </ul>
                  </li>
                  <li>
                    ARCHINZA reserves the right to withhold or deduct credits or
                    other features or benefits obtained through the use of Promo
                    Codes by you or any other user in the event that ARCHINZA
                    determines or believes that the use or redemption of the
                    Promo Code was in error, fraudulent, illegal, or in
                    violation of the applicable Promo Code terms or these Terms.
                  </li>
                </ol>
              </div>
              <h4 className="heading">
                {" "}
                DISCLAIMER OF WARRANTY; LIMITED LIABILITY
              </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    The Archinza Pro(s) expressly agrees that use of the
                    Platform is at the Archinza Pro(s) sole risk. Neither
                    ARCHINZA, its holding or subsidiaries, Business Associates
                    nor any of their respective employees, agents, and third
                    party content providers warrant that use of the Platform
                    will be uninterrupted or error-free; nor do they make any
                    warranty as to:
                    <ul>
                      <li>
                        the results that may be obtained from use of this
                        Platform, or{" "}
                      </li>
                      <li>
                        the accuracy, reliability or content of any information,
                        service or merchandise provided through the Platform.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Platform are made accessible on an “as is” basis without
                    warranties of any kind, either express or implied,
                    including, but not limited to, warranties of title or
                    implied warranties of merchantability or fitness for a
                    particular purpose, other than those warranties which are
                    implied by and incapable of exclusion, restriction or
                    modification under the laws applicable to this agreement.
                  </li>
                  <li>
                    We do not give any warranty that the Services or the
                    Platform are free from viruses or anything else which may
                    have a harmful effect on any technology.
                  </li>
                  <li>
                    We reserve the right to change, modify substitute, suspend
                    or remove without notice any information or Voucher or
                    service from the Platform forming part of the Service from
                    time to time. Your access to the Platform and/or the
                    Services may also be occasionally restricted to allow for
                    repairs, maintenance or the introduction of new facilities
                    or services. We will attempt to restore such access as soon
                    as we reasonably can. We assume no responsibility for
                    functionality which is dependent on your browser or other
                    third party software to operate. For the avoidance of doubt,
                    we may also withdraw any information or Voucher from the
                    Platform or Services at any time.
                  </li>
                  <li>
                    We reserve the right to block access to and/or to edit or
                    remove any material which in our reasonable opinion may give
                    rise to a breach of this Agreement.
                  </li>
                  <li>
                    This disclaimer of liability applies to any damages or
                    injury caused by any failure of performance, error,
                    omission, interruption, deletion, defect, delay in operation
                    or transmission, computer virus, communication line failure,
                    theft or destruction or unauthorized access to, alteration
                    of, or use of record, whether for breach of contract,
                    tortuous behavior, negligence, or under any other cause of
                    action. The Archinza Pro(s) specifically acknowledges that
                    ARCHINZA is not liable for the defamatory, offensive or
                    illegal conduct of other users or third-parties and that the
                    risk of injury from the foregoing rests entirely with the
                    User.
                  </li>
                  <li>
                    In no event shall ARCHINZA, or any Business Associates,
                    Third Party Content Providers, Third Party Advertisers or
                    Third Party Service Providers, producing or distributing the
                    Website or the contents hereof, mobile application and any
                    software, be liable for any damages, including, without
                    limitation, direct, indirect, incidental, special,
                    consequential or punitive damages arising out of the use of
                    or inability to use the Platform. The Archinza Pro(s) hereby
                    acknowledges that the provisions of this Clause shall apply
                    to all content on the Platform.
                  </li>
                  <li>
                    In addition to the terms set forth above, neither ARCHINZA,
                    nor its subsidiaries and Business Associates, Third Party
                    Service Providers or Third Party Content Providers shall be
                    liable regardless of the cause or duration, for any errors,
                    inaccuracies, omissions, or other defects in, or
                    untimeliness or inauthenticity of, the information contained
                    within the Platform, or for any delay or interruption in the
                    transmission thereof to the user, or for any claims or
                    losses arising therefrom or occasioned thereby. None of the
                    foregoing parties shall be liable for any third-party claims
                    or losses of any nature, including without limitation lost
                    profits, punitive or consequential damages.
                  </li>
                  <li>
                    ARCHINZA will not be liable for indirect, special, or
                    consequential damages (or any loss of revenue, profits, or
                    data) arising in connection with this Agreement, even if we
                    have been advised of the possibility of such damages.
                    Further, our aggregate liability arising with respect to
                    this Agreement will not exceed the total Offer price paid or
                    payable to you under this Agreement.
                  </li>
                  <li>
                    ARCHINZA shall be under no liability whatsoever in respect
                    of any loss or damage arising directly or indirectly out of
                    the decline of authorization for any Transaction, on Account
                    of the Cardholder having exceeded the preset limit mutually
                    agreed by us with our acquiring bank from time to time.
                  </li>
                </ol>
              </div>
              <h4 className="heading">MONITORING:</h4>
              <div className="desc">
                <p className="para">
                  ARCHINZA shall have the right, but not the obligation, to
                  monitor the content of the Platform at all times, including
                  any reviews, chat rooms, forums and uploads that may
                  hereinafter be included as part of the Platform, to determine
                  compliance with this Agreement and any operating rules
                  established by ARCHINZA, as well as to satisfy any applicable
                  law, regulation or authorized government request. Without
                  limiting the foregoing, ARCHINZA shall have the right to
                  remove any material that ARCHINZA, in its sole discretion,
                  finds to be in violation of the provisions hereof or otherwise
                  objectionable.
                </p>
              </div>
              <h4 className="heading">PRIVACY</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    The Archinza Pro(s) acknowledges that all discussion for
                    ratings, comments, reviews on Archinza Business/Event pages,
                    chat rooms/ bots and/or other message or communication
                    facilities, are public and not private communications, and
                    that, therefore, others may read the User’s communications
                    without the User’s knowledge. ARCHINZA does not control or
                    endorse the content, messages or information found on any
                    Archinza Business/Event pages, and, therefore, ARCHINZA
                    specifically disclaims any liability concerning the Archinza
                    Business(es)/Event uploads and any actions/infringements of
                    copyright resulting from the User’s participation in above
                    mentioned uploads or reviews, including any objectionable
                    content. Generally, any communication which the User posts
                    on the Website (whether in chat rooms, discussion groups,
                    message boards, reviews or otherwise) is considered to be
                    non-confidential.
                  </li>
                  <li>
                    The Archinza Pro(s) acknowledges that if any review is found
                    on their Archinza Business/Event pages and it is false or
                    untrue, ARCHINZA have no liability in the same.
                  </li>
                  <li>
                    If particular web pages permit the submission of
                    communications that will be treated by ARCHINZA as
                    confidential, that fact will be stated in the privacy
                    policy. By posting comments, messages or other information
                    on the Platform, Archinza Pro(s) grants ARCHINZA the right
                    to use such comments, messages or information for
                    promotions, advertising, market research or any other lawful
                    purpose. For more information see ARCHINZA’s{" "}
                    <Link to={privacypolicyURL}>Privacy Policy</Link>.
                  </li>
                </ol>
              </div>
              <h4 className="heading">INDEMNIFICATION</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    You acknowledge and expressly agree that use of this
                    Platform is at your sole and own risk. You agree to defend,
                    indemnify and hold ARCHINZA, its affiliates and business
                    partners as well as their respective directors, officers,
                    trustees and employees harmless from any and all
                    liabilities, costs and expenses, including reasonable
                    attorneys’ fees, related to any violation of these terms of
                    use by you or users of your account, or in any way arising
                    out of the use of this web site, including without
                    limitation, the placement or transmission of any information
                    or other materials on this web site by you or users of your
                    account.
                  </li>
                  <li>
                    By accepting these Terms, you agree to indemnify, keep us
                    indemnified and otherwise hold harmless, ARCHINZA, its
                    officers, employees, agents and other partners from any
                    direct, indirect, incidental, special, consequential or
                    exemplary damages, and all other losses, costs, changes,
                    demands, proceedings, and actions, howsoever incurred by
                    ARCHINZA arising from any claims or legal proceedings which
                    are brought or threatened against us by any person resulting
                    from: (a) your use of the Platform or Services of ARCHINZA;
                    (b) unauthorized access to the Platform and ARCHINZA
                    Services; or (c) any breach of these Terms by you or any
                    other matter relating to the Platform and ARCHINZA Services.
                  </li>
                  <li>
                    In the event that any of your enquiries submitted via the
                    Website infringe any rights of any third party, you shall,
                    at your own expense and at our discretion, either obtain the
                    right to use such contribution or render such contribution
                    free from infringement.
                  </li>
                </ol>
              </div>
              <h4 className="heading">LICENSE GRANT</h4>
              <div className="desc">
                <p className="para">
                  By posting communications on or through this Website or mobile
                  application, the User shall be deemed to have granted to
                  archinza.com, a royalty-free, perpetual, irrevocable &
                  non-exclusive license to use, reproduce, modify, publish,
                  edit, translate, distribute, perform, and display the
                  communication alone or as part of other works in any form,
                  media, or technology whether now known or hereafter developed
                  (even if deleted at a future date by the user/brand) , and to
                  sublicense such rights through multiple tiers of sublicensees.
                </p>
              </div>
              <h4 className="heading">TERMINATION </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA reserves the right to terminate access to certain
                    areas or features of the Web Site to registered User(s) at
                    any time without assigning any reason and with or without
                    notice to such User(s). ARCHINZA also reserves the universal
                    right to deny access to particular User(s) to any or all of
                    its services or content without any prior notice or
                    explanation in order to protect the interests of ARCHINZA
                    and/ or other User(s) of the Platform. ARCHINZA further
                    reserves the right to limit, deny or create different access
                    to the Platform and its features with respect to different
                    User(s), or to change any or all of the features of the
                    Platform or introduce new features without any prior notice
                    to User(s).
                  </li>
                  <li>
                    One User can have only one Account with a unique e-mail ID
                    and unique phone number. If ARCHINZA has any suspicion or
                    knowledge that any of its Users have created multiple
                    Accounts with different e-mail addresses or phone numbers
                    (including but not limited to account creation by using
                    false names or providing misleading data for creating those
                    Accounts or using disposable email addresses or disposable
                    phone numbers) to take undue advantage of the promotional
                    benefits being provided on the Platform, then ARCHINZA may
                    while reserving its rights to initiate civil and/or criminal
                    proceedings against such User(s) may also at its sole
                    discretion terminate, suspend, block, restrict, cancel the
                    Account of such User(s) and/or disqualify that User and any
                    related Users from availing the services ordered or undue
                    benefits availed through these accounts.{" "}
                  </li>
                  <li>
                    ARCHINZA reserves the right to terminate the
                    membership/subscription of any User(s) temporarily or
                    permanently for any of the following reasons:
                    <ul>
                      <li>
                        If any false information in connection with their
                        account registered with ARCHINZA is provided by such
                        User(s), or if such User(s) are engaged in fraudulent or
                        illegal activities/transactions.
                      </li>
                      <li>
                        If such User(s) breaches any provisions of the
                        Agreement.
                      </li>
                      <li>
                        If such User(s) utilizes the Platform to send spam
                        messages or repeatedly publish the same product
                        information.
                      </li>
                      <li>
                        If such User(s) posts any material that is not related
                        to trade or business cooperation.
                      </li>
                      <li>
                        If such User(s) impersonates or unlawfully uses another
                        person’s or business entity’s name to post information
                        or conduct business in any manner.{" "}
                      </li>
                      <li>
                        If such User(s) is involved in unauthorized access, use,
                        modification, or control of the Platform database,
                        network or related services.
                      </li>
                      <li>
                        If such User(s) obtains by any means another registered
                        User(s) Username and/or Password.
                      </li>
                      <li>
                        Or any other User(s) activity that may not be in
                        accordance with the ethics and honest business
                        practices.
                      </li>
                    </ul>
                  </li>
                  <li>
                    If ARCHINZA terminates the membership of any registered
                    User(s) of ARCHINZA, such person will not have the right to
                    re-enroll or join the Platform under a new account or name
                    unless invited to do so in writing by ARCHINZA. In any case
                    of termination, no subscription/membership fee/charges paid
                    by the User(s) will be refunded. User(s) acknowledge that
                    inability to use the Platform wholly or partially for
                    whatever reason may have adverse effect on their business.
                    User(s) hereby agree that in no event shall ARCHINZA be
                    liable to any User(s) or any third parties for any inability
                    to use the Web Site (whether due to disruption, limited
                    access, changes to or termination of any features on the Web
                    Site or otherwise), any delays, errors or omissions with
                    respect to any communication or transmission, or any damage
                    (direct, indirect, consequential or otherwise) arising from
                    the use of or inability to use the Platform or any of its
                    features.
                  </li>
                  <li>
                    All such provisions wherein the context so requires,
                    including Clauses on Intellectual Property Rights,
                    Disclaimer of Warranty, Limitation of Liability, License
                    Grant, Indemnification and Termination above will survive
                    termination of this Agreement.
                  </li>
                  <li>
                    Our right to terminate this Agreement shall not prejudice
                    any other right or remedy we may have in respect of any
                    breach or any rights, obligations or liabilities accrued
                    prior to termination.
                  </li>
                </ol>
              </div>
              <h4 className="heading">THIRD- PARTY CONTEXT</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    ARCHINZA, similar to an Internet Service Provider, is a
                    distributor (and not a publisher) of content supplied by
                    third parties or User(s). Accordingly, ARCHINZA does not
                    have editorial control over such content posted on Archinza
                    Business(es)/Event pages. Any opinions, advice, statements,
                    services, offers, or other information or content expressed
                    or made available by third parties, including Third Party
                    Content Providers, or any other User(s) are those of the
                    respective author(s) or distributors and not of ARCHINZA.
                  </li>
                  <li>
                    Neither ARCHINZA nor any third-party provider of information
                    guarantees the accuracy, completeness, or usefulness of any
                    content, nor its merchantability or fitness for any
                    particular purpose (refer to the Clause for ‘Disclaimer of
                    Warranty, Limitation of Liability’ above for the complete
                    provisions governing limitation of liabilities and
                    disclaimers of warranty).
                  </li>
                  <li>
                    In many instances, the content available through this
                    Platform represents the opinions and judgments of the
                    respective information provider, User, or other users not
                    under contract with ARCHINZA. In that scenario, ARCHINZA
                    neither endorses nor is responsible for the accuracy or
                    reliability of any opinion, advice, upload or statement made
                    on the Platform by anyone other than authorized ARCHINZA
                    employee/spokespersons while acting in official capacities.
                  </li>
                  <li>
                    Under no circumstances will ARCHINZA be liable for any loss
                    or damage caused by User’s reliance on information obtained
                    through the Platform. It is the responsibility of User to
                    evaluate the accuracy, completeness or usefulness of any
                    information, opinion, advice etc. or other content available
                    on the Platform.
                  </li>
                </ol>
              </div>
              <h4 className="heading">LINKS TO THIRD PARTY PLATFORM</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    Links to third party Platform are provided on Web Site as a
                    convenience to User(s) and Archinza Pro(s). User(s) and
                    Archinza Pro(s) acknowledge and agree that ARCHINZA does not
                    have any control over the content of such web Platform and/
                    or any information, resources or materials provided therein.
                  </li>
                  <li>
                    ARCHINZA may allow User(s) and Archinza Pro(s) access to
                    content, products or services offered by third parties
                    through hyperlinks (in the form of word link, banners,
                    channels or otherwise) to the web Platform offered by such
                    third parties (
                    <strong>"Third Party Web/Mobile Platform"</strong>).
                    ARCHINZA advises its User(s) and Archinza Pro(s) to read the
                    terms and conditions of use and/or privacy policies
                    applicable in respect of such Third Party Web Platform prior
                    to using or accessing such Third Party Web Platform. Users
                    acknowledge and agree that ARCHINZA has no control over any
                    content offered on Third Party Web Platform, does not
                    monitor such Third Party Web Platform, and shall in no
                    manner be deemed to be liable or responsible to any person
                    for such Third Party Platform, or any content, products or
                    services made available thereof.
                  </li>
                </ol>
              </div>
              <h4 className="heading">ADVERTISEMENT:</h4>
              <div className="desc">
                <p className="para">
                  ARCHINZA may place advertisements in different locations on
                  the Platform and at different points during use of the
                  Service. These locations and points may change from time to
                  time - but we will always clearly mark which goods and
                  services are advertisements (i.e. from persons other than us),
                  so that it is clear to you which goods and services are
                  provided on an objective basis and which are not (i.e. the
                  advertisements). For this purpose, if anything is being
                  advertised by the Archinza Pro(s), you are required to have a
                  marking that shows that it is an “advertisement” or
                  “promotion”.
                </p>
              </div>
              <h4 className="heading">FORCE MAJEURE:</h4>
              <div className="desc">
                <div className="para">
                  Without prejudice to any other provision herein, ARCHINZA
                  shall not be liable for any loss, damage or penalty as a
                  result of any delay in or failure to deliver or otherwise
                  perform hereunder due to any cause beyond ARCHINZA's control,
                  including, without limitation, acts of the User/
                  Divapreneur(s), embargo or other governmental act, regulation
                  or request affecting the conduct of ARCHINZA's business, fire,
                  explosion, accident, theft, vandalism, riot, acts of war,
                  strikes or other labor difficulties, lightning, flood,
                  windstorm or other acts of God.
                </div>
              </div>
              <h4 className="heading">GOVERNING LAW:</h4>
              <div className="desc">
                <div className="para">
                  This Agreement and the Privacy Policy shall be governed in all
                  respects by the laws of Indian Territory. ARCHINZA considers
                  itself and intends itself to be subject to the jurisdiction of
                  the Courts of Delhi, India only. The parties to this Agreement
                  hereby submit to the exclusive jurisdiction of the courts of
                  Delhi, India.
                </div>
              </div>
              <h4 className="heading">DISPUTE RESOLUTION:</h4>
              <div className="desc">
                <div className="para">
                  Any claim, dispute or difference arising out of this Terms of
                  Use shall be referred to Arbitration in accordance with
                  Section 7 of The Arbitration and Conciliation Act, 1996. The
                  Arbitration shall be subject to the Arbitration and
                  Conciliation Act, 1996 as maybe amended from time to time. The
                  Arbitration will be done by a Sole Arbitrator and such Sole
                  Arbitrator will be appointed by ARCHINZA. The Arbitration will
                  be conducted in accordance with the rules for conduct of
                  Arbitration proceedings then in force and applicable to the
                  proceedings. The Seat and Venue of Arbitration shall be New
                  Delhi. The proceedings shall be undertaken in English. The
                  Arbitration award shall be final and binding on the parties.
                </div>
              </div>
              <h4 className="heading">NOTICES</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    All notices or demands to or upon ARCHINZA shall be
                    effective if in writing and shall be deemed to be duly made
                    when sent to ARCHINZA to E2/6, Vasant Vihar, New Delhi
                    110057, India.
                  </li>
                  <li>
                    All notices or demands to or upon a User(s) and Archinza
                    Pro(s) shall be effective if either delivered personally,
                    sent by courier, certified mail, by facsimile or email to
                    the last-known correspondence, fax or email address provided
                    by the User(s) and Archinza Pro(s) on the Web Site, or by
                    posting such notice or demand on an area of the Web Site
                    that is publicly accessible without a charge.
                  </li>
                  <li>
                    Notice to a User(s) and Archinza Pro(s) shall be deemed to
                    be received by such User(s) and Archinza Pro(s) if and when
                    Web Site is able to demonstrate that communication, whether
                    in physical or electronic form, has been sent to such
                    User(s), or immediately upon Web Site’s posting such notice
                    on an area of the Web Site that is publicly accessible
                    without charge.
                  </li>
                </ol>
              </div>
              <h4 className="heading">SEVERABILITY:</h4>
              <div className="desc">
                <p className="para">
                  In the event that any provision of these Terms of Service is
                  determined to be unlawful, void or unenforceable, such
                  provision shall nonetheless be enforceable to the fullest
                  extent permitted by applicable law, and the unenforceable
                  portion shall be deemed to be severed from these Terms of
                  Service, such determination shall not affect the validity and
                  enforce-ability of any other remaining provisions.
                </p>
              </div>
              <h4 className="heading">
                PRODUCT/SERVICES TAKE DOWN POLICY: REPORTING VIOLATION OF
                INFRINGEMENT
              </h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    User(s) acknowledge and agree that ARCHINZA is not an
                    arbiter or judge of disputes concerning intellectual
                    property rights and as such cannot verify that Archinza
                    Pro(s) selling or supplying merchandise or providing
                    services on the Platform have the right to sell the
                    merchandise or provide the services offered by such Archinza
                    Pro(s). ARCHINZA encourages User(s) and Archinza Pro(s) to
                    assist ARCHINZA in identifying listings on the Platform
                    which in the User(s) and Archinza Pro(s) knowledge or belief
                    infringe their rights. User(s) and Archinza Pro(s) further
                    acknowledge and agree by taking down a listing, ARCHINZA
                    does not and cannot be deemed to be endorsing a claim of
                    infringement and further that in those instances in which
                    ARCHINZA declines to take down a listing, ARCHINZA does not
                    and cannot be deemed to be endorsing that the listing is not
                    infringing of third party rights or endorsing any sale or
                    supply of merchandise or services pursuant to or on account
                    of such listing.
                  </li>
                  <li>
                    However, ARCHINZA is committed to removing any infringing or
                    unlicensed product or service once an authorized
                    representative of the rights owner properly reports them to
                    us. ARCHINZA sincerely wants to ensure that item listings do
                    not infringe upon the copyright, trademark or other
                    intellectual property rights of third parties. ARCHINZA have
                    the ability to identify and request removal of allegedly
                    infringing firms/ consultants/
                    events/products/brands/services and materials. Any person or
                    company who holds intellectual property rights (such as a
                    copyright, trademark or patent) which may be infringed upon
                    by-products/brands/services listed on ARCHINZA is encouraged
                    to report the same to us through the Help section of the
                    Platform.
                  </li>
                </ol>
              </div>
              <h4 className="heading">MISCELLANEOUS</h4>
              <div className="desc">
                <ol className="decimal_number">
                  <li>
                    This Agreement, our Terms of Sale and our Privacy Policy
                    (including our Cookie policy) constitutes the entire
                    agreement between the parties with respect to the subject
                    matter hereof. No representation, undertaking or promise
                    shall be taken to have been given or be implied from
                    anything said or written in negotiations between the parties
                    prior to this Agreement except as expressly stated in this
                    Agreement. Neither party shall have any remedy in respect of
                    any untrue statement made by the other upon which that party
                    relied on when entering into this Agreement (unless such
                    untrue statement was made fraudulently or was as to a matter
                    fundamental to a party’s ability to perform this Agreement)
                    and that party’s only remedies shall be for breach of
                    contract as provided in this Agreement. However, the Service
                    is provided to you under our operating rules, policies, and
                    procedures as published from time to time on the Website.
                  </li>
                  <li>
                    No delay or omission by either Party hereto to exercise any
                    right or power occurring upon any noncompliance or default
                    by the other party with respect to any of the terms of these
                    Terms shall impair any such right or power or be construed
                    to be a waiver thereof.{" "}
                  </li>
                  <li>
                    All provisions of this Agreement apply equally to and are
                    for the benefit of ARCHINZA, its subsidiaries, any holding
                    companies of ARCHINZA, its (for their) affiliated and it (or
                    their) Third Party Content Providers and licensors and each
                    shall have the right to assert and enforce such provisions
                    directly or on its own behalf (save that this Agreement may
                    be varied or rescinded without the consent of those
                    parties).{" "}
                  </li>
                </ol>
              </div>
              {/* <h4 className="heading">
                FEW POINTS MISSING FROM PREV DOCUMENT. PLEASE SEE IF COVERED?
              </h4>
              <div className="desc">
                <ul>
                  <li>
                    Territory: The Website or mobile application and the
                    Services and any purchase are directed solely at those who
                    access the Website from the Republic of India.{" "}
                    <a href="https://www.archinza.com/" target="_blank">
                      <strong>Archinza.com</strong>
                    </a>{" "}
                    {""}
                    makes no representation that Service (or any goods or
                    services) are available or otherwise suitable for use
                    outside the Republic of India. If you choose to access the
                    Website or mobile application (or use the Service or make a
                    purchase) from locations outside the Republic of India, you
                    do so on your own initiative and are responsible for the
                    consequences thereof.
                  </li>
                </ul>{" "}
                <br />
                <p className="para">
                  Above point is relevant as some users may be international
                  based, but may give an india address. Lets discuss
                </p>
              </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default Termsandcondition;
